import { useContext, useState } from 'react';
import { Container, Content, Progress } from 'react-bulma-components';
import '../assets/styles/about.css';
import { DataContext } from '../components/DataContext';
import { EditTeam, PersonInfo } from '../components/Team';


export function About() {
    const { team } = useContext(DataContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="about-container">
                <Container p={3}>
                    <Content mt={6}>
                        <h1 className='about-title'>About Us</h1>
                        <p className='about-subtitle'>Learn more about the Co-Innovation AI and the people behind it.</p>
                    </Content>
                    {team.length === 0 ?
                        <>
                            <h1>Loading...</h1>
                            <Progress color="link" />
                        </> :
                        <>
                            <Container>
                                <div class="fixed-grid has-4-cols">
                                    <div class="grid">
                                        {team.map(person => (
                                            <div class="cell" >
                                                <PersonInfo {...person} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Container>
                        </>
                    }
                    {/* <Button mt={3} onClick={() => setShowModal(true)}>
                        Edit
                    </Button> */}
                </Container>
                <EditTeam show={showModal} onClose={setShowModal} />
            </div>
        </>
    );
}