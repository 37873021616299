import React, { useContext, useEffect, useRef, useState } from 'react';
import '../assets/styles/login.css';
import { AuthContext } from '../components/AuthContext';

const { REACT_APP_BACKEND_URL } = process.env

export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { updateUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const isMounted = useRef(true); // Create a ref to track the component's mounted state

    useEffect(() => {
        // Clean up function to set isMounted to false when unmounted
        return () => {
            isMounted.current = false;
        };
    }, []);


    const handleLogin = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setError('Username and password cannot be empty');
            return;
        }

        setLoading(true); // Set loading to true when login is submitted

        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);

        try {
            const response = await fetch(REACT_APP_BACKEND_URL + 'token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData.toString(),
            });

            if (!response.ok) {
                console.error('Login failed:', response.json());
                throw new Error('The username or password provided is incorrect.');
            }

            const data = await response.json();
            const token = data.access_token;

            const userResponse = await fetch(REACT_APP_BACKEND_URL + 'user', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!userResponse.ok) {
                throw new Error('Failed to fetch user information');
            }

            const userData = await userResponse.json();
            updateUser(userData, token);
            console.log('User Information:', userData);
        } catch (error) {
            setError(error.message);
        } finally {
            if (isMounted.current) { // Only update state if mounted
                setLoading(false);
            }

        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h1 className="title">Login</h1>
                <form onSubmit={handleLogin}>
                    <div className="field">
                        <label className="label">Username</label>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                placeholder="Enter your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Password</label>
                        <div className="control password-container">
                            <input
                                className="input"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                                className="password-toggle"
                                role="button"
                                tabIndex="0"
                                aria-label={showPassword ? "Hide password" : "Show password"}
                                onClick={() => setShowPassword(!showPassword)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setShowPassword(!showPassword);
                                    }
                                }}
                            >
                                <i className={`fas fa-eye${showPassword ? "-slash" : ""}`}></i>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button className="login-button" type="submit">
                                {loading ? <span key={0}> <i className="fas fa-spinner fa-spin"></i></span> : 'Login'}
                            </button>
                        </div>
                    </div>
                    {error && (
                        <div className="error-message">
                            <p className="error"><i className="fas fa-exclamation-circle error-icon"></i> {error}</p>
                        </div>
                    )}
                </form>
            </div >
        </div >
    );
};