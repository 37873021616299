import { useContext } from 'react';
import { Button, Modal } from 'react-bulma-components';
import '../assets/styles/about.css';
import { DataContext } from './DataContext';

export function PersonInfo(props) {
    return (
        <div className="card">
            <img src={props.image} alt={props.name} className="card-image" />
            <div className="card-content">
                <h2 className="card-name">{props.name}</h2>
                <h3 className="card-job">{props.job}</h3>
                <hr className="divider" />
                <p className="card-background">{props.background}</p>
                <hr className="divider" />
                <div className="card-buttons">
                    <a href={`mailto:${props.email}`} className="button email-button">
                        <i className="fas fa-envelope icon-spacing"></i> Email
                    </a>
                    <a href={props.linkedin} className="button linkedin-button">
                        <i className="fab fa-linkedin icon-spacing"></i> LinkedIn
                    </a>
                </div>
            </div>
        </div>
    );
}

export function EditTeam({ show, onClose }) {
    const { tags, team, demos, demosAvailability, addEntry, updateEntry, deleteEntry } = useContext(DataContext);
    return (
        <Modal show={show} onClose={() => onClose(false)}>
            <Modal.Card>
                <Modal.Card.Header showClose>
                    <Modal.Card.Title>Edit Team</Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body>
                    <p>Coming soon...</p>
                </Modal.Card.Body>
                <Modal.Card.Footer>
                    <Button color="info" renderAs='span'>
                        Save
                    </Button>
                </Modal.Card.Footer>
            </Modal.Card>
        </Modal>
    );
}