import React, { createContext, useState } from 'react';

export const UrlStatusContext = createContext();

export const UrlStatusProvider = ({ children }) => {
    const [urlStatuses, setUrlStatuses] = useState({});

    const updateUrlStatus = (url, status) => {
        setUrlStatuses(prevState => ({ ...prevState, [url]: status }));
    };

    const checkUrlStatus = async (url, retries = 3) => {
        if (urlStatuses[url] !== undefined) {
            return; // Already checked
        }

        try {
            const response = await fetch(url, { method: 'HEAD' });
            if (response.ok) {
                updateUrlStatus(url, true);
            } else {
                throw new Error('Not OK');
            }
        } catch (error) {
            if (retries > 1) {
                setTimeout(() => checkUrlStatus(url, retries - 1), 1000);
            } else {
                updateUrlStatus(url, false);
            }
        }
    };

    return (
        <UrlStatusContext.Provider value={{ urlStatuses, checkUrlStatus }}>
            {children}
        </UrlStatusContext.Provider>
    );
};