import { useContext } from 'react';
import { Container, Progress } from 'react-bulma-components';
import { DataContext } from '../components/DataContext';
import { DemosPanel } from '../components/Panel';

export function Demos() {
  const { tags, team, demos } = useContext(DataContext);

  if (demos.length === 0 || tags.length === 0) {
    return (
      <Container p={3} mt={6}>
        <h1>Loading...</h1>
        <Progress color='link' />
      </Container>
    );
  } else {
    return (
      <Container p={3} mt={6}>
        <DemosPanel />
      </Container>
    );
  }
}