import React from 'react';
import { Element } from 'react-scroll';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import '../assets/styles/areas.css';
import '../assets/styles/demonstrators.css';
import '../assets/styles/intro.css';
import '../assets/styles/portfolio.css';


function Introduction() {
  return (
    <Element name="introduction" className="intro-slide">
      <div className="intro-content">
        <h1 className="intro-title">Empowering <br /> industries with <br /> ai solutions</h1>
        <div className="intro-subtitle">
          <p>Welcome to the Axians Portugal Innovation Platform.</p>
          <p>Here you will find showcased AI-driven solutions developed by the AI community.</p>
        </div>
      </div>
    </Element>
  );
}

class AreasOfFocus extends React.Component {
  constructor(props) {
    super(props);
    this.areas = [
      {
        title: "Computer Vision",
        description: "Enabling machines to analyze visual data for improved decision-making and operational efficiency.",
        image: require("../assets/images/Card_Computer-Vision.jpg")
      },
      {
        title: "Natural Language Processing",
        description: "Enhancing communication by allowing machines to understand and generate human language seamlessly.",
        image: require("../assets/images/Card_NLP.jpg")
      },
      {
        title: "Fraud & Anomaly Detection",
        description: "Advanced AI techniques that identify irregular patterns and behaviors in data, helping organizations mitigate risks and safeguard assets.",
        image: require("../assets/images/Card_Fraud_Detection.jpg")
      },
      {
        title: "Large Language Models",
        description: "Advanced AI systems designed to understand and produce human-like text, transforming interactions and content generation.",
        image: require("../assets/images/Card_Large_Language_Models.jpg")
      },
      {
        title: "Problem Optimization",
        description: "Detecting patterns and implementing strategies that enhance decision-making and drive efficiency in complex business challenges.",
        image: require("../assets/images/Card_Problem-Optimization.jpg")
      }
    ];
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      centerMode: true,
      variableWidth: true,
    };

    return (
      <>
        <Element name="areas-of-focus" className="areas-of-focus">
          <h2 className='areas-of-focus-title'>Areas of Focus</h2>
          <h3 className='areas-of-focus-subtitle'>Our team specializes in the following domains:</h3>

          <Slider {...settings}>
            {this.areas.map((area, index) => (
              <div key={`${area.title}-${index}`}>
                <div key={index} className="carousel-card" style={{ backgroundImage: `url(${area.image})` }}>
                  <div className="carousel-content">
                    <div className="carousel-title">{area.title}</div>
                    <div className="carousel-description">{area.description}</div>
                  </div>
                </div>
              </div>
            ))}

          </Slider>
        </Element>
      </>
    );
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function Portfolio() {
  return (
    <Element name="portfolio" className="portfolio-slide">
      <div className="portfolio-content">
        <h1 className="portfolio-title">TRANSFORMING <br />  INDUSTRIES THROUGH <br /> INNOVATION</h1>
        <div className="portfolio-description">
          <p>Explore our Innovation Portfolio, where cutting-edge AI solutions are designed to address real-world challenges across various sectors.</p>
          <p>Discover our 28+ AI-based solutions across 9 market segments, empowering and transforming our clients' future.</p>
        </div>
        <button className="portfolio-button" onClick={() => window.open('Innovation Portfolio_2024_V3.2.pdf', '_blank')}>
          View Portfolio
        </button>
      </div>
    </Element>
  );
}

function Demonstrators() {
  return (

    <Element name="demonstrators" className="demonstrators-slide">
      <div className="demonstrators-content">
        <h1 className="demonstrators-title">EXPERIENCE OUR <br /> SOLUTIONS IN ACTION</h1>
        <div className="demonstrators-description">
          <p>Access our interactive demonstrators to see how our innovative AI solutions operate in real-world scenarios.
            Engage with the technology and discover how it can drive success in your industry.</p>
        </div>
        <button className="demonstrators-button" onClick={() => window.location.href = '/demos'}>
          View Demonstrators
        </button>
      </div>
      <div className="demonstrators-image"></div>
    </Element>
  );
}


export function Home() {
  return (
    <>
      <Introduction />
      <AreasOfFocus />
      <Portfolio />
      <Demonstrators />
    </>
  );
}