import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Columns,
  Container,
  Content,
  Form,
  Icon,
  Panel,
  Tag
} from 'react-bulma-components';

import '../assets/styles/about.css';
import '../assets/styles/demo.css';
import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';
import { UrlStatusContext } from './UrlStatusContext.js';

function renderTag(tag, activeTechTag, handleTagClick) {
  const active = tag === activeTechTag;
  return (
    <Panel.Tabs.Tab
      onClick={() => handleTagClick(tag)}
      className={`panel-tabs-tab ${active ? 'active' : ''}`}
      style={{ color: active ? '#1200FF' : '#2d2d2d', borderBottom: active ? '1px solid #1200FF' : 'none', borderRadius: '0' }}
    >
      {tag}
    </Panel.Tabs.Tab>
  );
}

export function renderDemoCard(demo, urlStatuses, searchText, activeTechTag, activeVerticalTags) {
  const isDemoUrlAvailable = demo.demo_url.trim() !== "";


  console.log("Demo URL is ", demo.demo_url, " and Is Available: ", urlStatuses[demo.demo_url]);
  if (
    (demo.name.toLowerCase().includes(searchText) || searchText === "") &&
    (demo.tags.includes(activeTechTag) || activeTechTag === 'All') &&
    (activeVerticalTags.length === 0 || activeVerticalTags.some(tag => demo.verticals.includes(tag)))
  ) {
    return (
      <Card key={demo.name} className='demo-card mb-4'>
        <Card.Header>
          <Card.Header.Title className="demo-card-title">
            <p className="demo-title-text">{demo.name}</p>
            <Tag className={urlStatuses[demo.demo_url] ? "tag-live" : "tag-not-live"}>
              LIVE
            </Tag>
          </Card.Header.Title>
        </Card.Header>
        <Card.Image className="demo-card-image" size="4by3" src={demo.image} alt={`${demo.name} image`} />
        <Card.Content className="demo-card-content">
          <Content>
            <p className="demo-card-description">{demo.description}</p>
            <hr className='divider' />
            <p className="demo-card-tags"><b>Technologies</b> <br />{demo.tags.join(', ')}</p>
            <hr className='divider' />
          </Content>
          <Button
            target="_blank"
            rel="noopener noreferrer"
            className={urlStatuses[demo.demo_url] ? "demo-card-button" : "demo-card-button active"}
            onClick={() => window.open(demo.demo_url)}
            size="small"
            disabled={!isDemoUrlAvailable}
          >
            <Icon>
              <i className="fas fa-external-link-alt"></i>
            </Icon>
            <span>Go to demo</span>
          </Button>
        </Card.Content>


      </Card>
    );
  }
  return null;
}

export function DemosPanel() {
  const { tags, demos, verticals } = useContext(DataContext);
  const { urlStatuses, checkUrlStatus } = useContext(UrlStatusContext);
  useEffect(() => {
    demos.forEach(demo => {
      if (demo.demo_url.trim() !== "") {
        checkUrlStatus(demo.demo_url);
      }
    }
    );
    return () => { };
  }, [demos, checkUrlStatus]);

  const { user } = useContext(AuthContext);
  const canEdit = user && user.profile === 'admin';

  // const { demosAvailability } = useContext(DemoContext);

  const techTags = ["All", ...tags.map(tag => tag.name)];

  // Show all vertical tags
  const verticalTags = verticals.map(vertical => vertical.name);

  const [searchText, setSearchText] = useState('');
  const [activeTechTag, setActiveTechTag] = useState(techTags[0]);
  const [activeVerticalTags, setActiveVerticalTags] = useState(verticalTags);
  const [selectedTags, setSelectedTags] = useState([]);
  const [addDemo, setAddDemo] = useState(false);
  const newDemo = { 'name': '', 'description': '', 'tags': [], 'icon': '', 'demo_url': '', 'portfolio_url': '', 'image': '', 'business_units': [], 'verticals': [], 'year': '' };

  // console.log("Demos Availability: ", demosAvailability);

  const handleVerticalTagClick = (tag) => {
    if (activeVerticalTags.includes(tag)) {
      setActiveVerticalTags(activeVerticalTags.filter(t => t !== tag));
    } else {
      setActiveVerticalTags([...activeVerticalTags, tag]);
    }
  };

  const resetFilters = () => {
    setActiveVerticalTags(verticalTags);
    setActiveTechTag(techTags[0]);
    setSelectedTags([]);
  };

  useEffect(() => {
    setActiveVerticalTags(verticalTags);

  }, []);

  return (
    <Container>
      <div>
        <Form.Control className='search-bar' >
          <Icon align="left">
            <i className="fas fa-search"></i>
          </Icon>
          <Form.Input
            className='input'
            placeholder="Search"
            type="text"
            onChange={e => setSearchText(e.target.value.toLowerCase())}
          />
        </Form.Control>
        <div className='filter-buttons'>
          <p className='tabs-text'>Areas:</p>
          <Panel.Tabs className="panel-tabs">
            {techTags.map(tag => renderTag(tag, activeTechTag, setActiveTechTag))}
          </Panel.Tabs>
        </div>
        {/* <hr style={{ margin: '10px 0' }} /> */}
        <div className="filter-buttons" style={{ marginTop: '10px' }}>
          <p className='filter-text'>Filters applied: </p>
          {verticals.map(vertical => {
            const isDisabled = !demos.some(demo => demo.verticals.includes(vertical.name));
            return (
              <button
                key={vertical.name}
                className={`button filter-button ${isDisabled ? 'disabled' : ''} ${activeVerticalTags.includes(vertical.name) ? 'active' : ''}`}
                onClick={() => !isDisabled && handleVerticalTagClick(vertical.name)}
                disabled={isDisabled}
                active={activeVerticalTags.includes(vertical.name)}
              >
                {vertical.name}
              </button>
            );
          })}
          <button className='reset-button' onClick={resetFilters} style={{ marginLeft: '10px' }}>
            Reset Filters
          </button>
        </div>
      </div>

      <Columns className='columns is-multiline'>
        {demos
          .filter((demo) => {
            const matchesSearchText = demo.name.toLowerCase().includes(searchText) || searchText === "";
            const matchesTechTag = demo.tags.includes(activeTechTag) || activeTechTag === 'All';
            const matchesVerticalTags = activeVerticalTags.length === 0 || activeVerticalTags.some(tag => demo.verticals.includes(tag));
            const matchesSelectedTags = selectedTags.length === 0 || selectedTags.some(tag => demo.tags.includes(tag));
            const shouldRender = matchesSearchText && matchesTechTag && matchesVerticalTags && matchesSelectedTags;

            return shouldRender;
          })
          .map((demo) => (
            <Columns.Column key={demo.name} size="one-quarter">
              {renderDemoCard(
                demo, urlStatuses, searchText, activeTechTag, activeVerticalTags
              )}
            </Columns.Column>
          ))}
      </Columns>

      {/* {canEdit &&
        <div style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 1000 }}>
          <Button
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            onClick={() => setAddDemo(true)}
          >
            <Icon>
              <i className="fas fa-plus"></i>
            </Icon>
            <span>Add a new demo</span>
          </Button>
          <EditInfo props={newDemo} show={addDemo} onClose={() => setAddDemo(false)} update={false} />
        </div>
      } */}
    </Container>
  );
}
