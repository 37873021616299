import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';

const { REACT_APP_BACKEND_URL } = process.env

export function DataProvider({ children }) {
  const [tags, setTags] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [team, setTeam] = useState([]);
  const [demos, setDemos] = useState([]);

  // const [demosAvailability, setDemosAvailability] = useState({});
  // const isFetchedRef = useRef(false);

  const { token } = useContext(AuthContext);

  const fetchData = (endpoint) => {
    fetch(REACT_APP_BACKEND_URL + 'collection/' + endpoint,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => response.json())
      .then(data => {
        getSetDataFunction(`collection/${endpoint}`)(data);
        localStorage.setItem(endpoint, JSON.stringify(data));
      });
  };

  // const fetchDemoAvailability = (data) => {
  //   const availability = {};
  //   const fetchPromises = Array.isArray(data) ? data.map(demo => {
  //     if (demo.demo_url === "") {
  //       availability[demo.name] = false;
  //       return Promise.resolve();
  //     } else {
  //       return fetch(demo.demo_url)
  //         .then(response => {
  //           console.log("DataProvider", demo.demo_url, response.ok);
  //           availability[demo.name] = response.ok;
  //           // return response.ok;
  //         })
  //         .catch(() => {
  //           console.log("DataProvider", demo.demo_url, "failed");
  //           availability[demo.name] = false;
  //           // return false;
  //         })
  //         .finally(() => {
  //           console.log("DataProvider", demo.demo_url, "finally", availability[demo.name]);
  //         }
  //         );
  //     }
  //   }) : [];

  //   Promise.all(fetchPromises).then(() => setDemosAvailability(availability));
  // }
  // const fetchDemoAvailability = async (data) => {
  //   const availability = {};

  //   // Prepare an array of promises for each fetch request
  //   const fetchPromises = data.map(demo => {
  //     if (demo.demo_url === "") {
  //       availability[demo.name] = false;
  //       return Promise.resolve();
  //     } else {
  //       // Perform fetch and return the promise
  //       return fetch(demo.demo_url)
  //         .then(response => {
  //           availability[demo.name] = response.ok;  // Store result in availability object
  //         })
  //         .catch(() => {
  //           availability[demo.name] = false;  // On error, mark as unavailable
  //         });
  //     }
  //   });

  //   // Wait for all fetch promises to complete
  //   await Promise.all(fetchPromises);

  //   // Only update the state if necessary to avoid re-renders
  //   setDemosAvailability(prevAvailability => {
  //     const newAvailability = { ...prevAvailability, ...availability };

  //     // Only update if availability actually changed to avoid loops
  //     if (JSON.stringify(newAvailability) !== JSON.stringify(prevAvailability)) {
  //       return newAvailability;
  //     }
  //     return prevAvailability;
  //   });
  // };

  useEffect(() => {
    const savedTags = localStorage.getItem('tags');
    if (!savedTags || JSON.parse(savedTags).length === 0) {
      fetchData('tags');
      console.log('fetching tags');
    }
    else {
      setTags(JSON.parse(savedTags));
      console.log('loaded tags from local storage:', tags);
    }

    const savedTeam = localStorage.getItem('team');
    if (!savedTeam || JSON.parse(savedTeam).length === 0) {
      fetchData('team');
      console.log('fetching team');
    }
    else {
      setTeam(JSON.parse(savedTeam));
      console.log('loaded team from local storage:', team);
    }

    const savedDemos = localStorage.getItem('demos');
    if (!savedDemos || JSON.parse(savedDemos).length === 0) {
      fetchData('demos');
      console.log('fetching demos');
    }
    else {
      setDemos(JSON.parse(savedDemos));
      console.log('loaded demos from local storage:', demos);
    }

    const savedVerticals = localStorage.getItem('verticals');
    if (!savedVerticals || JSON.parse(savedVerticals).length === 0) {
      fetchData('verticals');
      console.log('fetching verticals');
    }
    else {
      setVerticals(JSON.parse(savedVerticals));
      console.log('loaded verticals from local storage:', verticals);
    }

    const savedBusinessUnits = localStorage.getItem('businessUnits');
    if (!savedBusinessUnits || JSON.parse(savedBusinessUnits).length === 0) {
      fetchData('businessUnits');
      console.log('fetching businessUnits');
    }
    else {
      setBusinessUnits(JSON.parse(savedBusinessUnits));
      console.log('loaded businessUnits from local storage:', businessUnits);
    }

    // if (!isFetchedRef.current) {
    //   isFetchedRef.current = true;
    //   fetchDemoAvailability(demos);
    // }
  }, []);

  const getSetDataFunction = (endpoint) => {
    switch (endpoint) {
      case 'collection/tags':
        return setTags;
      case 'collection/team':
        return setTeam;
      case 'collection/verticals':
        return setVerticals;
      case 'collection/businessUnits':
        return setBusinessUnits;
      default:
        return setDemos;
    }
  };

  const addEntry = (endpoint, entry) => {
    const setData = getSetDataFunction(endpoint);
    setData(prevData => [...prevData, entry]);

    fetch(REACT_APP_BACKEND_URL + endpoint + "/insert", {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(entry)
    });
  };

  const updateEntry = (endpoint, updatedEntry) => {
    const setData = getSetDataFunction(endpoint);
    setData(prevData => prevData.map(entry => entry._id === updatedEntry._id ? updatedEntry : entry));

    const updatedEntryNoId = { ...updatedEntry };
    delete updatedEntryNoId._id;
    fetch(REACT_APP_BACKEND_URL + endpoint + `/update/${updatedEntry._id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedEntryNoId)
    });
  };

  const deleteEntry = (endpoint, id) => {
    const setData = getSetDataFunction(endpoint);
    setData(prevData => prevData.filter(entry => entry._id !== id));

    fetch(REACT_APP_BACKEND_URL + endpoint + `/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  };

  return (
    <DataContext.Provider value={{ tags, team, demos, verticals, businessUnits, addEntry, updateEntry, deleteEntry }}>
      {children}
    </DataContext.Provider>
  );
}